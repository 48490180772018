import { template as template_5693a69ddbcd4228920f9e67f2224318 } from "@ember/template-compiler";
const WelcomeHeader = template_5693a69ddbcd4228920f9e67f2224318(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
