import { template as template_4a63531723184045b149267ca8a72e94 } from "@ember/template-compiler";
const FKLabel = template_4a63531723184045b149267ca8a72e94(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
